<template>
  <div
    class="popup_wrap"
    style="width:800px;"
  >
    <button
      class="layer_close"
      @click.prevent="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">
        Pick Up Place
      </h1>
      <div class="content_box">
        <div
          id="realgrid"
          style="width:100%; height:600px;"
        />
      </div>
      <!-- paging -->
      <div
        v-if="items.length > 0"
        class="paging"
      >
        <a
          v-if="isPaging1"
          class="btn prev"
          @click.prevent="setPrevPage()"
        >Prev</a>
        <a>{{ currentPage + 1 }}/{{ totalPage }}</a>
        <a
          class="btn next"
          @click.prevent="setNextPage()"
        >Next</a>
      </div>
    </div>
  </div>
</template>
<script>

import { GridView, LocalDataProvider } from 'realgrid'
import sr from '@/api/rest/trans/sr'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  {
    fieldName: 'plcEnm',
    dataType: 'text'
  },
  {
    fieldName: 'cyCd',
    dataType: 'text'
  },
  {
    fieldName: 'cyNm',
    dataType: 'text'
  },
  {
    fieldName: 'statusNm',
    dataType: 'text'
  },
  {
    fieldName: 'rstChk',
    dataType: 'text'
  }
]

export const columns = [
  {
    name: 'plcEnm',
    fieldName: 'plcEnm',
    type: 'text',
    width: '130',
    header: {
      text: '위치'
    }
  },
  {
    name: 'cyCd',
    fieldName: 'cyCd',
    type: 'text',
    width: '60',
    header: {
      text: 'Code'
    }
  },
  {
    name: 'cyNm',
    fieldName: 'cyNm',
    type: 'text',
    width: '150',
    header: {
      text: 'Pick Up 장소'
    }
  },
  {
    name: 'statusNm',
    fieldName: 'statusNm',
    type: 'text',
    width: '100',
    header: {
      text: '상태'
    }
  },
  {
    name: 'rstChk',
    fieldName: 'rstChk',
    type: 'text',
    width: '50',
    header: {
      text: 'Select'
    },
    renderer: {
        type: 'html',
        callback: function (grid, cell, w, h) {
            let str = ''
            const value = cell.value
            if (value === '0') {
                str = '<a class="button blue sm" style="cursor:pointer">' + app.getMessageProperty('btn.CMBTK050') + '</a>'
            } else {
                str = '<a class="button gray sm disabled" style="cursor:pointer">' + app.getMessageProperty('btn.CMBTK048') + '</a>'
            }

            return str
        }
    }
  }
]

export default {
  name: 'SrPickUpPlace',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          selectRow: '',
          cntrSize: '',
          cntrType: '',
          pickUpDate: '',
          hidPolPortCode: '', //테스트용(세션)
          iotCntrYn: ''
        }
      }
    }
  },
  data () {
    return {
      items: [],
      isPaging1: true,
      currentPage: 0,
      totalPage: 0
    }
  },
  mounted: function () {
    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)

    this.setMessageProperty()
    gridView.displayOptions.fitStyle = 'fill'
    gridView.displayOptions.rowHeight = 30
    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    //리얼그리드 팝업 HEIGHT 조절
    $('#realgrid').height('350px')

    //인디케이터 없애기
    gridView.setRowIndicator({
      visible: false
    })
    //상태바 없애기
    gridView.setStateBar({
      visible: false
    })
    //체크바 없애기
    gridView.setCheckBar({
      visible: false
    })
    window.app = this
    const $vm = this
    //API : S/R Inquiry Pick Up 장소 리스트 가져오기
    $vm.getSrPickUpPlace()
    //그리드 이벤트 모음
    $vm.gridEvents()
    //페이징
    gridView.setPaging(true, 10)
  },
  methods: {
    selectedRow: function (index) {
      //console.log(provider.getValue(index))
    },
    getSrPickUpPlace () {
      this.items = []
      let form = this.parentInfo

      sr.getSrPickUpPlace(form).then((data) => {
        const list = data.data.srPickUpList

        //셋팅할 데이터 to array
        list.forEach((item) => {
            item.statusNm = item.rstChk === '1' ? this.$t('msg.CSBK100.406') : ''
            this.items.push(item)
        })

        //그리드 데이터 셋팅
        provider.setRows(this.items)

        //페이징(axios는 비동기 처리기 때문에 promise 처리된 then() 안에서 사용)
        this.currentPage = gridView.getPage() //현재 페이지 반환
        this.totalPage = gridView.getPageCount() //전체페이지 개수 반환
      })
    },
    setPrevPage: function () {
      gridView.setPage(this.currentPage - 1)
      this.currentPage = gridView.getPage()
    },
    setNextPage: function () {
      gridView.setPage(this.currentPage + 1)
      this.currentPage = gridView.getPage()
    },
    gridEvents () {
      const THIS = this
      gridView.onCellClicked = function (grid, clickData) {
        const current = gridView.getCurrent()
        const jsonData = provider.getJsonRow(current.dataRow)

        //선택 버튼 제어
        if (clickData.fieldName === 'rstChk') {
          if (jsonData.rstChk === '1') {
            alert('선택불가')
          } else {
            THIS.$emit('selectFunc', jsonData)
            THIS.$emit('close')
          }
        }
      }
    },
    getMessageProperty (key) {
      return this.$t(key)
    },
    //다국어 적용
    setMessageProperty () {
      const header1 = gridView.getColumnProperty('plcEnm', 'header')
      const header2 = gridView.getColumnProperty('cyCd', 'header')
      const header3 = gridView.getColumnProperty('cyNm', 'header')
      const header4 = gridView.getColumnProperty('statusNm', 'header')
      const header5 = gridView.getColumnProperty('rstChk', 'header')

      header1.text = this.$t('msg.CSBK100.080') //위치
      header2.text = this.$t('msg.CSBK100.134') //CODE
      header3.text = this.$t('msg.CSBK100.106') //Pick Up 장소
      header4.text = this.$t('msg.CSBK100.135') //상태
      header5.text = this.$t('msg.CSBK100.136') //SELECT

      gridView.setColumnProperty('plcEnm', 'header', header1)
      gridView.setColumnProperty('cyCd', 'header', header2)
      gridView.setColumnProperty('cyNm', 'header', header3)
      gridView.setColumnProperty('statusNm', 'header', header4)
      gridView.setColumnProperty('rstChk', 'header', header5)
    }
  }
}
</script>

<style scoped>
</style>
