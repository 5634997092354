import Send from '../../trans.client.js'

//주석정의방법 : 컨트롤러 메소드명 - 메소드에 대한 간단 주석 - REST URI
export default {
    //S/R Inquiry > Pick up 장소 선택 팝업
    getSrPickUpPlace (formData) {
        return Send({
            url: '/trans/sr/pick-up-place',
            method: 'get',
            params: {
                selectRow: formData.selectRow || '0',
                hidPolCtrCode: formData.hidPolCtrCode,
                cntrSize: formData.cntrSize || '40',
                cntrType: formData.cntrType || 'GP',
                pickUpDate: formData.pickUpDate,
                hidPolPortCode: formData.hidPolPortCode,
                iotCntrYn: formData.iotCntrYn //테스트용 - 실제론 세션 사용
            }
        })
    },
    //SHA 제약 체크
    findBkgCancelCd (bkgNo) {
      return Send({
        url: '/trans/sr/cancel-possible/' + bkgNo,
        method: 'get'
      })
    },
    //SHA 부킹 캔슬 로직
    cancelSR (formData) {
      return Send({
        url: '/trans/sr/cancel',
        method: 'post',
        data: formData
      })
    }
}
